<template>
  <div class="big-data-container" v-loading="loading">
    <div class="top-search-box p-b-0 m-b-20">
      <el-form ref="search" :model="searchObj" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item label="站点名称">
              <el-input v-model="searchObj.siteName" clearable size="mini" placeholder="请输入设备名称" @change="onSearch" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item v-if="isAdmin" label="公司名称">
              <el-input v-model="searchObj.companyName" clearable size="mini" placeholder="请输入设备名称" @change="onSearch" />
            </el-form-item>
          </el-col>
          <el-col :span="12" class="right-btns">
            <el-button icon="el-icon-search" class="search-box-btn" size="mini" type="primary" @click.stop="onSearch">查询</el-button>
            <el-button v-if="isAdmin" icon="el-icon-circle-plus-outline" class="search-box-btn" size="mini" type="primary" @click.stop="onAddSite">添加站点</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="content-list-data-box">
      <el-table :data="siteList" style="width: 100%" :height="height"
        :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
        <el-table-column label="站点名称" prop="siteName">
          <template slot-scope="scope">
            <el-link type="primary" :href="`/#/site/detail/${scope.row.siteId}`">{{ scope.row.siteName }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="站点位置" prop="regionName">
          <template slot-scope="scope">
            <el-link type="primary" @click.stop="showRegion(scope.row)">{{ scope.row.regionName }}</el-link>
          </template>
        </el-table-column>
        <el-table-column label="所属公司" align="center" prop="companyName" width="400"></el-table-column>
        <el-table-column label="维护人员" align="center" prop="mobileName" width="200"></el-table-column>
        <el-table-column label="联系方式" align="center" prop="mobile" width="120"></el-table-column>
      </el-table>
      <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
        :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
        :page-size="pageData.size" :total="pageData.total" :pager-count="7" :current-page="pageData.current" @current-change="pageChange" />
    </div>
    <el-dialog title="站点定位" destroy-on-close :close-on-press-escape="false" show-close
      :close-on-click-modal="true" v-if="dialogVisible.region" :visible="dialogVisible.region"
        @close="onCloseDialog('region')" width="80%">
      <region ref="region" :site-info="siteInfo" :height="height"></region>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import siteApi from "@/api/site";

import region from "./region";

let pageData = {
  current: 1,
  size: 20,
  total: 0
}

let searchObj = {};

const protoSearchObj = JSON.stringify(searchObj);
const protoPageData = JSON.stringify(pageData);

export default {
  name: "Site-List",
  components: {
    region
  },
  computed: {
    ...mapGetters(['user']),
  },
  data () {
    return {
      isAdmin: true,
      isAdminUser: false,
      loading: false,
      dialogVisible: {
        region: false
      },
      searchObj: searchObj,
      pageData: pageData,
      siteList: [],
      height: 0
    }
  },
  created () {
    console.log("site");
    this.isAdmin = this.user.tenantId === 0;
    this.height = document.body.scrollHeight - 224;
    this.getDatas();
  },
  mounted () {
    this.$route.meta.deactivated = () => {
      this.pageData = pageData = JSON.parse(protoPageData);
      this.searchObj = searchObj = JSON.parse(protoSearchObj);
    }
  },
  methods: {
    async getDatas () {
      try {
        this.loading = true;

        const params = Object.assign({
          page: (this.pageData.current - 1) * this.pageData.size,
          size: this.pageData.size,
          type: 2
        }, this.searchObj);

        if (!this.isAdmin) {
          params.staffCode = `,${this.user.businessId},`;
        }

        const res = await siteApi.list(params);

        this.siteList = res.data;
        this.pageData.total = res.paging.total;
      } finally {
        this.loading = false;
      }
    },
    onAddSite () {
      this.$router.push({ path : `/site/add` });
    },
    showRegion (row) {
      this.dialogVisible.region = true;
      this.siteInfo = Object.assign({}, row);
      this.siteInfo.location = [row.longitude, row.latitude];
    },
    async onCloseDialog (refName, ignore) {
      // 通过 emit 发送的关闭请求一律通过
      // 如果点击 close 图标则一律直接关闭
      if (ignore || !this.$refs[refName] || await this.$refs[refName].onClosed()) {
        // 设置 visable 后,会重新触发 close 所以需要先 remove edit
        delete this.$refs[refName];
        this.dialogVisible[refName] = false;
      }
    },
    onSearch () {
      this.pageData.current = 1;
      this.getDatas();
    },
    pageChange (e) {
      this.pageData.current = e;
      this.getDatas();
    },
    onSizeChange (e) {
      this.pageData.size = e;
      this.pageData.current = 1;
      this.getDatas();
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .el-dialog {
  margin-top: 5vh !important;
  height: 90vh !important;
}
</style>
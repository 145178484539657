<template>
  <div>
    <el-form label-width="80">
      <region-core :is-add="false" :is-index="true" :is-detail="true" :show-only="true" :site-info="siteInfo" :height="height" />
    </el-form>
  </div>
</template>
<script>
import RegionCore from "./core";
export default {
  components: {
    RegionCore
  },
  props: {
    siteInfo: undefined,
    height: undefined
  },
  methods: {
    onClosed () {
      return true;
    },
  }
}
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-form",
        { attrs: { "label-width": "80" } },
        [
          _c("region-core", {
            attrs: {
              "is-add": false,
              "is-index": true,
              "is-detail": true,
              "show-only": true,
              "site-info": _vm.siteInfo,
              height: _vm.height,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }